import * as React from "react"
import { graphql } from "gatsby"

// import { CSSTransition } from "react-transition-group"

// import Filters from "../../../components/filters/Filters"
import Seo from "../../../components/seo/seo"
// import "./store.css"
// import ButtonArrow from "../../../components/button/ButtonArrow"
import ProductAd from "../../../components/product/ProductAd"

import ProductCardCollect from "../../../components/product/ProductCardCollect"
// import TextTitle from "../../../components/section/TextTitle"
// import { FilterHooks } from "../../../utils/filterHooks"
// import { globalHistory } from "@reach/router"
const SanityCollection = ({ data }) => {
  const { collection, allSanitySettings } = data
  const products = collection.products
  // console.log(products)
  const campaigns = collection.campaigns
  const settings = allSanitySettings.nodes[0].cardColour

  return (
    <>
      <Seo title={`Collection: ${collection.title}`} />
      <div className="-mt-1 -mb-1">
        <div className="w-full pb-px overflow-hidden">
          <div className="grid grid-cols-2 lg:grid-cols-4 -mt-0.5">
            {products.length > 4 &&
              campaigns.map(campaign => {
                return (
                  <ProductAd
                    campaign={campaign}
                    settings={settings}
                    key={campaign._key}
                  ></ProductAd>
                )
              })}
            {products.length > 0 ? (
              products.map((product, index) => {
                const sale = product.store?.variants[0]?.store?.compareAtPrice
                const featuredTag = product.featuredTag
                return (
                  <ProductCardCollect
                    settings={settings}
                    style={{ order: `${index + 1}` }}
                    product={product}
                    key={product._id}
                    productId={product.store.id}
                    slug={product.store.slug.current}
                    title={product.store.title}
                    sale={sale ? true : false}
                    minPrice={product.store.priceRange.minVariantPrice}
                    collaboration={featuredTag ? featuredTag : ""}
                  ></ProductCardCollect>
                )
              })
            ) : (
              <span className="block col-span-4 wrap py-2.5">0 products</span>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SanityCollection

export const query = graphql`
  query($slug__current: String!) {
    collection: sanityCollection(slug: { current: { eq: $slug__current } }) {
      title
      slug {
        current
      }
      campaigns {
        _key
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH)
            url
          }
        }
        position
        mobilePosition
        show {
          desktop
          mobile
        }
        span
      }
      products {
        ...SaneShop
      }
    }
    sanityOptions: allSanityProduct(
      filter: { store: { isDeleted: { eq: false } } }
    ) {
      nodes {
        hexcodeImage {
          hexcode
          color
          _key
        }
        store {
          options {
            values
            name
          }
        }
      }
    }

    allSanitySettings {
      nodes {
        cardColour {
          hex
        }
      }
    }
  }
`
